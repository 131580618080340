import * as React from "react";
import {MediaType} from "../../../types/MediaType";
import {useAppToasts, useModal} from "@ova-studio/react-hyper-admin";
import MediaLibraryModal from "../../../MediaLibraryModal";
import {MediaSelectAction} from "../../../types/MediaSelectAction";
import FormMediaSelectContext, {useFormMediaSelectContext} from "./FormMediaSelectContext";
import {Button, ButtonProps, Dropdown} from "react-bootstrap";
import noImage from "./noImage";
import {Media} from "../../../types/Media";
import clsx from "clsx";
import {useMediaLibraryService} from "../../../services/MediaLibraryService";
import {useSingleMedia} from "../../../hooks";

type FormMediaSelectBodyMediaPayload = {
    src: string,
    name: string,
}

type FormMediaSelectBodyMediaProps = {
    children: (data: FormMediaSelectBodyMediaPayload|undefined) => React.ReactNode,
}

const FormMediaSelectBodyMedia = ({ children } : FormMediaSelectBodyMediaProps) => {
    const { media } = useFormMediaSelectContext();

    const payload = React.useMemo<FormMediaSelectBodyMediaPayload|undefined>(() => {
        if (media === undefined) {
            return undefined;
        }

        if (media === null) {
            return {
                src: noImage,
                name: 'не вибрано',
            }
        }

        return {
            src: media.image?.url ?? noImage,
            name: media.filename ?? 'Немає зображення',
        }
    }, [media])

    return (
        <React.Fragment>
            {children(payload)}
        </React.Fragment>
    )
}

type FormMediaSelectBodyButtonProps = Omit<ButtonProps, 'onClick'>;

const FormMediaSelectBodyButton = ({ variant = 'secondary', size = 'sm', className, children, ...props }: FormMediaSelectBodyButtonProps) => {
    const { openModal, media } = useFormMediaSelectContext();
    const mediaManager = useMediaLibraryService().mediaManager;

    if (!media) {
        return (
            <Button variant={variant} size={size} className={clsx('py-0 px-1', className)} {...props} onClick={openModal} disabled={media === undefined}>
                {children ?? <i className='mdi mdi-select-search font-22'/>}
            </Button>
        )
    }

    const actions = mediaManager.resolveMediaActions(media, { allowDelete: false });

    return (
        <Dropdown>
            <Dropdown.Toggle variant={variant} size={size} className={clsx('py-0 px-1 arrow-none', className)} {...props}>
                {children ?? <i className='mdi mdi-pencil font-22'/>}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item onClick={openModal}>Змінити</Dropdown.Item>
                {actions.map((action, index) => (
                    <Dropdown.Item key={index} onClick={action.action}>{action.name}</Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    )
}

type FormMediaSelectWrapperBodyProps = {
    mediaId: number | null,
    onChange: (media: number | string | null) => void,
    types?: MediaType[],
    children: React.ReactNode,
}

const FormMediaSelectBody = ({ mediaId, onChange, types, children }: FormMediaSelectWrapperBodyProps) => {
    const mediaSelectModalState = useModal();

    const media = useSingleMedia(mediaId);
    const toastService = useAppToasts();

    const handleChange = (media: Media) => {
        if (types && !types.includes(media.type)) {
            toastService.createToast({
                variant: 'danger',
                title: 'Помилка',
                body: 'Невірний тип медіафайлу',
                showTime: 5000,
            })
            return;
        }

        onChange(media.id);
    }

    return (
        <React.Fragment>
            <MediaLibraryModal
                state={mediaSelectModalState}
                handleSubmit={data => handleChange(data[0])}
                closeOnSubmit={true}
                selectAction={MediaSelectAction.SelectSingle}
                types={types}
            />

            <FormMediaSelectContext.Provider value={{ media, mediaId, openModal: mediaSelectModalState.open }}>
                {children}
            </FormMediaSelectContext.Provider>
        </React.Fragment>
    )
}

export default Object.assign(FormMediaSelectBody, {
    Media: FormMediaSelectBodyMedia,
    Button: FormMediaSelectBodyButton,
});
