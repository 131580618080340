import * as React from "react";
import {Picture} from "../types/Picture";
import clsx from "clsx";

export type PictureRenderProps = {
    data: Picture,
    className?: string,
    alt?: string,
    lazy?: boolean,
    isProcessing?: boolean,
}

const PictureRender = ({ data, alt, lazy, className, isProcessing }: PictureRenderProps) => {
    return (
        <picture className={clsx(className, isProcessing && 'ova-engine-media--processing')}>
            {data.sources.map((source, index) => (
                <source
                    key={index}
                    srcSet={source.url}
                    type={`image/${source.ext}`}
                    media={source.width ? `(min-width: ${source.width}px)` : undefined}
                />
            ))}
            <img
                src={data.url}
                alt={alt ?? ''}
                loading={lazy ? 'lazy' : 'eager'}
                style={{
                    aspectRatio: data.aspectRatio,
                    backgroundImage: data.pixel ? `url(${data.pixel})` : '',
                }}
            />
        </picture>
    )
}

export default PictureRender;
