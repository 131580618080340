import * as React from 'react'
import { createRoot } from 'react-dom/client'
import { OvaEngineAdmin, OvaEngineAppConfig, ModulesRegistry } from "@ova-engine/engine-admin"
import {EditorConfig} from "@ova-studio/react-block-editor";
import blocks from "./site/blocks";

import "@ova-engine/engine-admin/dist/css/style.css"

const config : OvaEngineAppConfig = {
    sentryDsn: process.env.SENTRY_DSN || undefined,
    appRelease: process.env.APP_RELEASE_VERSION || 'development',
}

const editorConfig: Partial<EditorConfig> = {
    blocks: blocks,
}

ModulesRegistry.disableModule('comments');
ModulesRegistry.disableModule('notices');
ModulesRegistry.disableModule('ads');

const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <OvaEngineAdmin config={config} editorConfig={editorConfig} />,
)
