import * as React from 'react';
import {Media, useMediaList, MediaGallery as BaseMediaGalleryRender} from "@ova-studio/react-media-library";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import Icon from "@mdi/react";
import {mdiDelete, mdiPencil} from "@mdi/js";
import {MediaGalleryNode} from "../../MediaGalleryNode";
import {EDIT_MEDIA_GALLERY_COMMAND} from "../../commands";
import {MoveProps} from "../../../common/BaseBlockNode";
import MoveButtons from "../../../common/MoveButtons";
import EditorWaitDataBlock from "../../../../../components/EditorWaitDataBlock";
import EditorAlert from "../../../../../components/EditorAlert";

type MediaGalleryWrapperProps = {
    handleOpenEditModal: () => void,
    handleRemove: () => void,
    children: React.ReactNode,
} & MoveProps

const MediaGalleryWrapper = ({ children, handleOpenEditModal, handleRemove, ...moveProps } : MediaGalleryWrapperProps) => (
    <div className="editor-gallery">
        <div className='ova-editor-block-actions-container'>
            <div className='ova-editor-block-actions'>
                <MoveButtons {...moveProps} />
                <button onClick={handleOpenEditModal}><Icon path={mdiPencil}/></button>
                <button onClick={handleRemove}><Icon path={mdiDelete}/></button>
            </div>
        </div>
        {children}
    </div>
)

type MediaGalleryRenderProps = {
    data: ReturnType<typeof useMediaList>,
    mediaIds: number[],
    captions: Record<number, string>,
}

const MediaGalleryRender = ({ mediaIds, data, captions } : MediaGalleryRenderProps) => {
    const items = React.useMemo<Media[]>(() => {
        return mediaIds.reduce((acc, id) => {
            const media = data[id];
            if (media) {
                return [...acc, media];
            }
            return acc;
        }, [] as Media[]);
    }, [data, ...mediaIds]);

    const {isProcessing, canUseSchema} = React.useMemo(() => {
        return {
            isProcessing: items.some(media => media.processing_status !== 'done'),
            canUseSchema: items.every(media => media.flags.canUseSchema),
        };
    }, [items]);

    return (
        <React.Fragment>
            {isProcessing && <EditorAlert variant='warning' noSpace={true}>Деякі медіа ще обробляються</EditorAlert>}
            {!canUseSchema && <EditorAlert variant='error' noSpace={true}>Деякі медіа не містять даних, необхідних для роботи розширених результатів пошуку</EditorAlert>}
            <BaseMediaGalleryRender
                items={items}
                captions={captions}
            />
        </React.Fragment>
    )
}

type MediaGalleryProps = {
    node: MediaGalleryNode,
    mediaIds: number[],
    captions: Record<number, string>,
    handleRemove: () => void,
} & MoveProps

const MediaGallery = ({node, mediaIds, captions, handleRemove, ...moveProps}: MediaGalleryProps) => {

    const [editor] = useLexicalComposerContext();

    const ids = React.useMemo(() => [...mediaIds], [...mediaIds]);

    const media = useMediaList(ids);

    const _handleRemove = () => {
        editor.update(() => handleRemove())
    }

    const _handleOpenEditModal = () => {
        editor.dispatchCommand(EDIT_MEDIA_GALLERY_COMMAND, node)
    }

    return (
        <MediaGalleryWrapper handleOpenEditModal={_handleOpenEditModal} handleRemove={_handleRemove} {...moveProps}>
            <EditorWaitDataBlock
                data={media}
                render={(media) => (
                    <MediaGalleryRender
                        data={media}
                        captions={captions}
                        mediaIds={mediaIds}
                    />
                )}
            />
        </MediaGalleryWrapper>
    )
}

export default MediaGallery
