import * as React from "react";
import {BouncingLoader, FlexCentered, Modal, TopOverlayLoading, UseModalReturn} from "@ova-studio/react-hyper-admin";
import {Button} from "react-bootstrap";
import useImageEditorSave from "../components/ImageEditor/useImageEditorSave";
import {MediaEditResource} from "../types/MediaEditResource";

export type MediaImageEditorModalState = UseModalReturn<MediaEditResource>;

type MediaEditorModalProps = {
    state: MediaImageEditorModalState,
}

const ImageEditor = React.lazy(() => import('../components/ImageEditor'));

const LoadingFallback = () => <Modal.Body><FlexCentered><BouncingLoader /></FlexCentered></Modal.Body>;

const MediaEditorModal = ({ state }: MediaEditorModalProps) => {

    const { currentImgRef, isSaving, save } = useImageEditorSave({ close: state.close  });

    return (
        <Modal state={state} title='Редагувати зображення' fullscreen>
            {isSaving && <TopOverlayLoading />}
            {state.data ? (
                <React.Suspense fallback={<LoadingFallback />}>
                    <Modal.Body className='p-0'>
                        <ImageEditor media={state.data} onClose={state.close} ref={currentImgRef} />
                    </Modal.Body>
                    <Modal.Footer>
                        <div className='small text-muted me-auto'>
                            Powered by{' '}
                            <b>Filerobot Image Editor</b>
                        </div>

                        <Button variant='primary' onClick={() => save(state.data!.id, state.data!.filename)} disabled={isSaving}>Зберегти</Button>
                    </Modal.Footer>
                </React.Suspense>
            ) : <LoadingFallback />}
        </Modal>
    )
}

export default MediaEditorModal;
