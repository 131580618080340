import * as React from 'react';
import {ModuleConfig} from "../../helpers/ModulesRegistry";
import {makeCrudRoute} from "@ova-studio/react-hyper-admin";

const CategoryForm = React.lazy(() => import('./pages/CategoryForm'));
const TagForm = React.lazy(() => import('./pages/TagForm'));

const categoriesDatatableRenderConfig = () => import('./datatable/categoriesDatatableRenderConfig');
const tagsDatatableRenderConfig = () => import('./datatable/tagsDatatableRenderConfig');

const TermsModule : ModuleConfig = {
    navigation: {
        order: 700,
        item: {
            icon: 'tag-multiple',
            name: 'Рубрики та теги',
            children: [
                {
                    url: '/terms/categories',
                    name: 'Рубрики',
                },
                {
                    url: '/terms/tags',
                    name: 'Теги',
                },
            ]
        }
    },

    routes: [
        makeCrudRoute({
            type: 'simple',
            basePath: 'terms/categories',
            baseEndpoint: 'terms/categories',
            name: 'Категорії',
            index: {
                name: 'Категорії',
                renderConfig: categoriesDatatableRenderConfig,
            },
            forms: {
                createName: 'Створити категорію',
                updateName: 'Редагувати категорію "{data.name}"',
                element: <CategoryForm />,
                helpers: {
                    types: 'helpers/terms/category-types'
                },
            },
        }),
        makeCrudRoute({
            type: 'simple',
            basePath: 'terms/tags',
            baseEndpoint: 'terms/tags',
            name: 'Теги',
            index: {
                name: 'Теги',
                renderConfig: tagsDatatableRenderConfig,
            },
            forms: {
                createName: 'Створити тег',
                updateName: 'Редагувати тег "{data.name}"',
                element: <TagForm />,
            },
        }),
    ],
}

export default TermsModule;
