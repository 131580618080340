// @ts-ignore
import defaultIcon from '../assets/file/default.svg';
// @ts-ignore
import pdfIcon from '../assets/file/pdf.svg';
// @ts-ignore
import zipIcon from '../assets/file/zip.svg';
// @ts-ignore
import mp3Icon from '../assets/file/mp3.svg';

const resolveFileIcon = (mime: string) : string => {
    switch (mime) {
        case 'application/pdf':
            return pdfIcon;
        case 'application/zip':
            return zipIcon;
        case 'audio/mpeg':
            return mp3Icon;
        default:
            return defaultIcon;

    }
}

export default resolveFileIcon
