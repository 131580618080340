import * as React from "react";
import {useMediaLibraryService} from "./services/MediaLibraryService";
import {Media} from "./types/Media";
import {MediaId, MediaIds, MediaItemData} from "./services/MediaDataStore";

const makeIdsString = (ids: MediaIds) => ids.join(',');

export const useSingleMedia = (id: MediaId | null) : MediaItemData => {
    const mediaStore = useMediaLibraryService().mediaStore;

    const subscribe = React.useCallback(mediaStore.subscribe.bind(mediaStore, id), [mediaStore, id]);
    const getSnapshot = React.useCallback(mediaStore.getSingleData.bind(mediaStore, id), [mediaStore, id]);

    return React.useSyncExternalStore<Media | null | undefined>(subscribe, getSnapshot);
}

export const useMediaList = (ids: MediaIds) : Record<MediaId, MediaItemData> => {
    const mediaStore = useMediaLibraryService().mediaStore;
    const idsString = makeIdsString(ids);

    const subscribe = React.useCallback(mediaStore.subscribe.bind(mediaStore, ids), [mediaStore, idsString]);
    const getSnapshot = React.useCallback(mediaStore.getData.bind(mediaStore, ids), [mediaStore, idsString]);

    return React.useSyncExternalStore<Record<MediaId, MediaItemData>>(subscribe, getSnapshot);
}
