import * as React from 'react';
import {Form, Modal, useModal, UseModalReturn} from "@ova-studio/react-hyper-admin";
import {FormValues} from "@ova-studio/react-hyper-admin/dist/Form/types";
import {Alert, Button, Dropdown} from "react-bootstrap";
import {MediaGalleryNode} from "../MediaGalleryNode";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import {
    Media,
    MediaLibraryModal,
    MediaSelectAction,
    useMediaLibraryService,
    useMediaList
} from "@ova-studio/react-media-library";
// @ts-ignore
import fallbackImage from "../../../../assets/fallback_media.jpg";

type MediaGalleryItemProps = {
    index: number,
    data: ReturnType<typeof useMediaList>,
    handleMoveUp?: () => void,
    handleMoveDown?: () => void,
    handleRemove?: () => void,
}

const MediaGalleryItem = ({ index, data, handleMoveUp, handleMoveDown, handleRemove } : MediaGalleryItemProps) => {

    const mediaId = Form.useWatch({ name: `media.${index}.mediaId` }) as number;

    const mediaManager = useMediaLibraryService().mediaManager;

    const media = data[mediaId];
    const image = media?.thumb?.url ?? fallbackImage;
    const filename = media?.filename ?? '...';
    const isProcessing = media?.processing_status !== 'done';
    const canUseSchema = !!media?.flags.canUseSchema;

    const editOptions = React.useMemo(() => {
        return media ? mediaManager.resolveMediaActions(media, { allowDelete: false }) : [];
    }, [media]);

    return (
        <div className='d-flex align-items-center gap-2 border-bottom border-1 border-secondary pb-2'>
            <div className='ratio ratio-1x1 position-relative' style={{width: 200}}>
                <img src={image} alt={filename} style={{objectFit: 'cover'}}/>
                <div className='bg-secondary-lighten position-absolute bottom-0 start-0 end-0 text-truncate text-nowrap small text-center' style={{ height: '1rem' }}>
                    {filename}
                </div>
            </div>
            <div className='flex-grow-1'>
                {isProcessing && <Alert variant='warning'>Медіа ще обробляється</Alert>}
                {!canUseSchema && <Alert variant='danger'>Потрібно заповнити інформацію про медіа</Alert>}
                <Form.HiddenInput name={`media.${index}.mediaId`}/>
                <Form.Input type='textarea' name={`media.${index}.caption`} placeholder='Підпис' groupClass='' rows={7} />
            </div>
            <div className='d-flex flex-column gap-1'>
                <Dropdown>
                    <Dropdown.Toggle variant='link' className='link-secondary p-0 mdi mdi-pencil font-22 arrow-none' />
                    <Dropdown.Menu>
                        {editOptions.map((option, index) => (
                            <Dropdown.Item key={index} onClick={() => option.action()}>{option.name}</Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
                <Button variant='link' className='link-secondary p-0 mdi mdi-arrow-up-bold font-22' onClick={handleMoveUp} disabled={!handleMoveUp} />
                <Button variant='link' className='link-secondary p-0 mdi mdi-arrow-down-bold font-22' onClick={handleMoveDown} disabled={!handleMoveDown} />
                <Button variant='link' className='link-danger p-0 mdi mdi-close-circle-outline font-22' onClick={handleRemove} disabled={!handleRemove} />
            </div>
        </div>
    )
}

const MediaGalleryItems = () => {
    const { fields, swap, remove, append } = Form.useFieldArray({ name: 'media' });
    const mediaIds = React.useMemo(() => fields.map(field => (field as any).mediaId), [fields]);

    const cnt = fields.length;

    const media = useMediaList(mediaIds);

    const addModalState = useModal();

    const handleAddModalSubmit = React.useCallback((media: Media[]) => {
        media.forEach(media => append({ mediaId: media.id, caption: '' }));
    }, []);

    return (
        <React.Fragment>
            {fields.map((field, index) => (
                <MediaGalleryItem
                    key={field.id}
                    index={index}
                    data={media}
                    handleMoveUp={index > 0 ? () => swap(index, index - 1) : undefined}
                    handleMoveDown={index < (cnt - 1) ? () => swap(index, index + 1) : undefined}
                    handleRemove={cnt > 2 ? () => remove(index) : undefined}
                />
            ))}

            <div>
                <Button variant='outline-secondary' onClick={addModalState.open} size='sm'>Додати ще...</Button>
            </div>

            <MediaLibraryModal
                state={addModalState}
                handleSubmit={handleAddModalSubmit}
                closeOnSubmit={true}
                selectAction={MediaSelectAction.SelectMultiple}
            />
        </React.Fragment>
    )
}

export type MediaGalleryEditFormState = UseModalReturn<MediaGalleryNode>;

type MediaGalleryEditFormProps = {
    state: MediaGalleryEditFormState,
}

const MediaGalleryEditForm = ({ state }: MediaGalleryEditFormProps) => {

    const [editor] = useLexicalComposerContext();

    const handleSubmit = React.useCallback(async (data: FormValues) => {
        state.close();
        editor.update(() => state.data?.handleUpdate(data));
    }, [editor, state.data]);

    return (
        <Modal title='Редагування галереї' state={state} size='lg'>
            <Form.Callback data={state.data?.getEditableData()} handleSubmit={handleSubmit} showSuccess={false}>
                <Modal.Body>
                    <div className='d-flex flex-column gap-2'>
                        <MediaGalleryItems />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Form.Submit>Зберегти</Form.Submit>
                </Modal.Footer>
            </Form.Callback>
        </Modal>
    );
}

export default MediaGalleryEditForm
