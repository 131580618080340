import {ActivityInfoSection} from "../types";

export type ActivityLinkOpts = {
    site_user_id?: number,
    ip?: string,
    client_id?: string,

    section?: ActivityInfoSection,
}

export default function makeActivityLink(opts: ActivityLinkOpts) : string {

    const params = new URLSearchParams();

    if (opts.site_user_id) {
        params.append('site_user_id', opts.site_user_id.toString());
    }
    if (opts.ip) {
        params.append('ip', opts.ip);
    }
    if (opts.client_id) {
        params.append('client_id', opts.client_id);
    }

    if (opts.section) {
        params.append('section', opts.section);
    }

    if (params.toString() === '') {
        return '/activity';
    }

    return `/activity?${params.toString()}`;

}
