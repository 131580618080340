import * as React from "react";
import {MediaDisplayProps} from "./MediaDisplayProps";
import PictureRender from "../PictureRender";

const MediaVideoDisplay = ({ data, caption, lazy }: MediaDisplayProps) => {
    if (!data.image) {
        return null;
    }

    return (
        <figure className='ova-engine-video' data-video-data-url={data.dataUrl}>
            <PictureRender data={data.image} alt={caption} lazy={lazy} className='ova-engine-video-placeholder' isProcessing={!data.is_processed} />
            {caption && <figcaption>{caption}</figcaption>}
        </figure>
    )
}

export default MediaVideoDisplay;
