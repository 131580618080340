import * as React from 'react';
import {MediaDisplay, useSingleMedia} from "@ova-studio/react-media-library";
import {MediaSingleData} from "../../types/MediaSingleData";
import MediaDisplayWrapper from "./MediaDisplayWrapper";
import {MoveProps} from "../../../common/BaseBlockNode";
import EditorWaitDataBlock from "../../../../../components/EditorWaitDataBlock";
import EditorAlert from "../../../../../components/EditorAlert";

export type MediaSingleProps = {
    mediaId: number,
    handleUpdate: (data: MediaSingleData) => void,
    handleRemove: () => void,
} & MediaSingleData & MoveProps

const MediaSingle = ({ mediaId, handleUpdate, handleRemove, caption, ...moveProps }: MediaSingleProps) => {

    const media = useSingleMedia(mediaId);

    return (
        <MediaDisplayWrapper handleUpdate={handleUpdate} handleRemove={handleRemove} data={{ caption }} media={media ?? undefined} {...moveProps}>
            <EditorWaitDataBlock
                data={media}
                render={(media) => (
                    <React.Fragment>
                        {media.processing_status !== 'done' && <EditorAlert variant='warning' noSpace={true}>Медіа ще обробляється</EditorAlert>}
                        {!media.flags.canUseSchema && <EditorAlert variant='error' noSpace={true}>Медіа не містить даних, необхідних для роботи розширених результатів пошуку</EditorAlert>}
                        <MediaDisplay data={media} caption={caption} disableInteraction={true} />
                    </React.Fragment>
                )}
            />
        </MediaDisplayWrapper>
    );
}

export default MediaSingle
