import * as React from "react";
import {MediaDisplayProps} from "./MediaDisplayProps";
import getTypeFromMime from "../../utils/getTypeFromMime";
import PictureRender from "../PictureRender";

const MediaEmbedDisplay = ({ data, caption, lazy }: MediaDisplayProps) => {
    if (!data.image) {
        return null;
    }

    return (
        <figure className='ova-engine-embed' data-code-url={'TODO'} data-social-type={getTypeFromMime(data.mime_type)}>
            <PictureRender data={data.image} alt={caption} lazy={lazy} className='ova-engine-embed-preview' isProcessing={!data.is_processed} />
            {caption && <figcaption>{caption}</figcaption>}
        </figure>
    )
}

export default MediaEmbedDisplay;
