import * as React from "react";
import {ModuleConfig} from "../../helpers/ModulesRegistry";
import {makeCrudRoute} from "@ova-studio/react-hyper-admin";

const PoolForm = React.lazy(() => import('./pages/PoolForm'))

const poolsDatatableRenderConfig = () => import('./datatable/poolsDatatableRenderConfig')

const PoolModuleConfig : ModuleConfig = {
    init: (app) => {
        app.navigation.GlobalButtons.registerDropdownItem('create', 'pool', {
            icon: 'poll',
            title: 'Опитування',
            url: '/pools/create',
        }, true);
    },

    navigation: {
        order: 500,
        item: {
            url: '/pools',
            icon: 'poll',
            name: 'Опитування',
        },
    },

    routes: [
        makeCrudRoute({
            type: 'simple',
            basePath: 'pools',
            baseEndpoint: 'pools',
            name: 'Опитування',
            index: {
                name: 'Опитування',
                renderConfig: poolsDatatableRenderConfig,
            },
            forms: {
                createName: 'Створення опитування',
                updateName: 'Редагування опитування',
                element: <PoolForm />
            }
        })
    ]
}

export default PoolModuleConfig;
