import * as React from "react";
import {ModuleConfig} from "../../helpers/ModulesRegistry";
import {makeCrudRoute} from "@ova-studio/react-hyper-admin";

const SpecialPageForm = React.lazy(() => import('./pages/SpecialPageForm'))
const StaticPageForm = React.lazy(() => import('./pages/StaticPageForm'))

const specialPagesDatatableRenderConfig = () => import('./datatable/specialPagesDatatableRenderConfig');
const staticPagesDatatableRenderConfig = () => import('./datatable/staticPagesDatatableRenderConfig');

const PagesModule : ModuleConfig = {
    navigation: {
        order: 800,
        item: {
            icon: 'text-box-outline',
            name: 'Сторінки',
            children: [
                {
                    url: '/pages/special',
                    name: 'Системні сторінки',
                },
                {
                    url: '/pages/static',
                    name: 'Статичні сторінки',
                },
            ]
        }
    },

    routes: [
        makeCrudRoute({
            type: 'simple',
            basePath: 'pages/special',
            baseEndpoint: 'pages/special',
            name: 'Системні сторінки',
            index: {
                name: 'Системні сторінки',
                renderConfig: specialPagesDatatableRenderConfig,
            },
            update: {
                name: 'Редагування системної сторінки',
                element: <SpecialPageForm />,
            },
        }),
        makeCrudRoute({
            type: 'simple',
            basePath: 'pages/static',
            baseEndpoint: 'pages/static',
            name: 'Статичні сторінки',
            index: {
                name: 'Статичні сторінки',
                renderConfig: staticPagesDatatableRenderConfig,
            },
            forms: {
                createName: 'Створення статичної сторінки',
                updateName: 'Редагування статичної сторінки',
                element: <StaticPageForm />,
            },
        }),
    ]
}

export default PagesModule;
