import * as React from "react";
import {ModuleConfig} from "../../helpers/ModulesRegistry";
import {makeCrudRoute, makeSimpleRoute} from "@ova-studio/react-hyper-admin";
import {Navigate} from "react-router-dom";

const noticeDatatableRenderConfig = () => import('./datatable/noticesDatatableRenderConfig');
const noticeCategoriesDatatableRenderConfig = () => import('./datatable/noticeCategoriesDatatableRenderConfig');
const noticeRegionsDatatableRenderConfig = () => import('./datatable/noticeRegionsDatatableRenderConfig');

const NoticeForm = React.lazy(() => import('./pages/NoticeForm'));
const NoticeCategoryForm = React.lazy(() => import('./pages/NoticeCategoryForm'));
const NoticeRegionForm = React.lazy(() => import('./pages/NoticeRegionForm'));

const NoticesModule : ModuleConfig = {
    navigation: {
        order: 600,
        item: {
            icon: 'bulletin-board',
            name: 'Оголошення',
            children: [
                {
                    url: '/notices/notices',
                    name: 'Оголошення',
                },
                {
                    url: '/notices/categories',
                    name: 'Категорії оголошень',
                },
                {
                    url: '/notices/regions',
                    name: 'Регіони оголошень',
                },
            ]
        }
    },

    routes: [
        makeSimpleRoute({
            name: 'Оголошення',
            path: '/notices',
            children: [
                makeSimpleRoute({
                    index: true,
                    name: 'Оголошення',
                    element: <Navigate to="/notices/notices" />,
                }),
                makeCrudRoute({
                    type: 'simple',
                    basePath: 'notices',
                    baseEndpoint: 'notices/notices',
                    name: 'Оголошення',
                    index: {
                        name: 'Список оголошень',
                        renderConfig: noticeDatatableRenderConfig,
                    },
                    forms: {
                        createName: 'Створення оголошення',
                        updateName: 'Редагування оголошення',
                        element: <NoticeForm />,
                        helpers: {
                            categories: 'helpers/notices/categories',
                            regions: 'helpers/notices/regions',
                        },
                    },
                }),
                makeCrudRoute({
                    type: 'simple',
                    basePath: 'categories',
                    baseEndpoint: 'notices/categories',
                    name: 'Категорії оголошень',
                    index: {
                        name: 'Категорії оголошень',
                        renderConfig: noticeCategoriesDatatableRenderConfig,
                    },
                    forms: {
                        createName: 'Створення категорії оголошень',
                        updateName: 'Редагування категорії оголошень',
                        element: <NoticeCategoryForm />,
                    },
                }),
                makeCrudRoute({
                    type: 'simple',
                    basePath: 'regions',
                    baseEndpoint: 'notices/regions',
                    name: 'Регіони оголошень',
                    index: {
                        name: 'Регіони оголошень',
                        renderConfig: noticeRegionsDatatableRenderConfig,
                    },
                    forms: {
                        createName: 'Створення регіону оголошень',
                        updateName: 'Редагування регіону оголошень',
                        element: <NoticeRegionForm />,
                    },
                }),
            ]
        })
    ]
}

export default NoticesModule;
