import * as React from 'react';
import {
    connectMenu,
    ContextMenu,
    ContextMenuTrigger,
    ContextMenuTriggerProps,
    MenuItem
} from "@firefox-devtools/react-contextmenu";
import {Media} from "../../types/Media";
import {useMediaLibraryService} from "../../services/MediaLibraryService";

export const menuType: string = 'media-context';

type MediaContextMenuProps = {
    id: string,
    trigger: {
        media: Media,
    },
}

const MediaContextMenu = ({ id, trigger }: MediaContextMenuProps) => {

    const mediaService = useMediaLibraryService().mediaManager;
    const actions = trigger?.media ? mediaService.resolveMediaActions(trigger.media, { allowDelete: true }) : [];

    return (
        <ContextMenu id={id}>
            {actions.map((action, index) => (
                <MenuItem key={index} onClick={action.action}>{action.name}</MenuItem>
            ))}
        </ContextMenu>
    );
}

export const MediaConnectedContextMenu = connectMenu(menuType)(MediaContextMenu);

type MediaContextMenuTriggerProps = {
    as?: ContextMenuTriggerProps['renderTag'],
    attributes?: ContextMenuTriggerProps['attributes'],
    media: Media,
    triggerOnLeftClick?: boolean,
    children: React.ReactNode,
}

export const MediaContextMenuTrigger = ({ as, attributes, media, triggerOnLeftClick, children } : MediaContextMenuTriggerProps) => {
    return (
        <ContextMenuTrigger id={menuType} media={media} renderTag={as} attributes={attributes} collect={(data) => data} triggerOnLeftClick={triggerOnLeftClick}>
            {children}
        </ContextMenuTrigger>
    );
}
