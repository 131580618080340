import * as React from "react";
import imageEditorTranslationUK_UA from "./imageEditorTranslationUK_UA";
import FilerobotImageEditor, {getCurrentImgDataFunction, TABS} from "react-filerobot-image-editor";
import {useImageEditorHelper} from "./ImageEditorHelper";
import {BouncingLoader, FlexCentered} from "@ova-studio/react-hyper-admin";
import {MediaEditResource} from "../../types/MediaEditResource";

type ImageEditorProps = {
    media: MediaEditResource;
    onClose: () => void;
}

const ImageEditor = React.forwardRef<getCurrentImgDataFunction, ImageEditorProps>(({ media, onClose }, currentImgRef) => {

    const data = useImageEditorHelper();

    if (!data) {
        return (
            <FlexCentered>
                <BouncingLoader />
                <p>Ініціалізація редактора, зачекайте...</p>
            </FlexCentered>
        )
    }

    return (
        <FilerobotImageEditor
            source={media.imageUrl}
            getCurrentImgDataFnRef={currentImgRef}
            onClose={onClose}
            savingPixelRatio={4}
            previewPixelRatio={4}
            translations={imageEditorTranslationUK_UA}
            defaultSavedImageType='jpg'
            disableSaveIfNoChanges={true}
            removeSaveButton={true}
            resetOnImageSourceChange={true}
            useBackendTranslations={false}
            tabsIds={[
                TABS.ADJUST,
                TABS.FINETUNE,
                TABS.FILTERS,
                TABS.WATERMARK,
                TABS.ANNOTATE,
            ]}
            Watermark={{
                gallery: data.watermarks,
                hideTextWatermark: true,
            }}
        />
    )
});

export default ImageEditor;
