import * as React from "react";
import EditorErrorBlock from "./EditorErrorBlock";

export type EditorErrorBoundaryProps = {
    children: React.ReactNode,
}

export type EditorErrorBoundaryState = {
    isError: boolean,
    error?: string,
}

class EditorErrorBoundary extends React.Component<EditorErrorBoundaryProps, EditorErrorBoundaryState> {
    constructor(props : EditorErrorBoundaryProps) {
        super(props);
        this.state = { isError: false };
    }

    static getDerivedStateFromError(error: unknown) {
        if (typeof error === 'string') {
            return { error };
        }

        if (error instanceof Error) {
            return { error: error.message };
        }

        return { error: true };
    }

    render() {
        if (this.state.isError) {
            return <EditorErrorBlock txt={this.state.error ?? 'Невідома помилка'} />;
        }

        return this.props.children;
    }
}

export default EditorErrorBoundary;
