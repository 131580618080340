import * as React from "react";
import {EditorLoaderBlock} from "../index";
import EditorErrorBoundary from "./EditorErrorBoundary";

export type EditorWaitDataBlockProps<T extends unknown> = {
    data: T | undefined | null,
    render: (data: T) => React.ReactNode,
}

const EditorWaitDataBlock = <T extends unknown>({ data, render }: EditorWaitDataBlockProps<T>) => {
    if (!data) {
        return <EditorLoaderBlock />
    }

    return (
        <EditorErrorBoundary>
            {render(data)}
        </EditorErrorBoundary>
    )
}

export default EditorWaitDataBlock;
