import * as React from "react";
import {ModuleConfig} from "../../helpers/ModulesRegistry";
import {makeCrudRoute, makeSimpleRoute} from "@ova-studio/react-hyper-admin";
import {Navigate} from "react-router-dom";

const AdBlockForm = React.lazy(() => import('./pages/AbBlockForm'));
const AdPlacesForm = React.lazy(() => import('./pages/AdPlacesForm'));
const AdVendorForm = React.lazy(() => import('./pages/AdVendorForm'));

const adBlocksDatatableRenderConfig = () => import('./datatable/adBlocksDatatableRenderConfig');

const AdsModuleConfig : ModuleConfig = {
    navigation: {
        order: 900,
        item: {
            icon: 'google-ads',
            name: 'Реклама',
            children: [
                {
                    url: '/ads/blocks',
                    name: 'Блоки',
                },
                {
                    url: '/ads/vendors',
                    name: 'Постачальники',
                },
                {
                    url: '/ads/places',
                    name: 'Місця',
                },
            ]
        }
    },

    routes: [
        makeSimpleRoute({
            path: '/ads',
            name: 'Реклама',
            children: [
                makeSimpleRoute({
                    index: true,
                    name: 'Реклама',
                    element: <Navigate to='./blocks' />
                }),
                makeCrudRoute({
                    type: 'simple',
                    basePath: 'blocks',
                    baseEndpoint: 'ads/blocks',
                    name: 'Рекламні блоки',
                    index: {
                        name: 'Рекламні блоки',
                        renderConfig: adBlocksDatatableRenderConfig,
                    },
                    forms: {
                        createName: 'Створення рекламного блоку',
                        updateName: 'Редагування рекламного блоку',
                        element: <AdBlockForm />,
                        helpers: {
                            places: 'helpers/ads/places',
                            conditions: 'helpers/ads/conditions',
                            vendors: 'helpers/ads/vendors',
                            ['google-aspect-ratios']: 'helpers/ads/google-aspect-ratios',
                            ['google-formats']: 'helpers/ads/google-formats',
                        }
                    }
                }),
                makeSimpleRoute({
                    path: 'places',
                    name: 'Місця',
                    element: <AdPlacesForm />,
                    loader: {
                        sync: {
                            data: {
                                endpoint: 'ads/places',
                            }
                        }
                    },
                    action: {
                        endpoint: 'ads/places',
                        method: 'post',
                    }
                }),
                makeCrudRoute({
                    type: 'simple',
                    basePath: 'vendors',
                    baseEndpoint: 'ads/vendors',
                    name: 'Постачальники',
                    index: {
                        name: 'Постачальники',
                    },
                    forms: {
                        createName: 'Створення постачальника',
                        updateName: 'Редагування постачальника',
                        element: <AdVendorForm />,
                    }
                }),
            ]
        })
    ]
}

export default AdsModuleConfig;
