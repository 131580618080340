import * as React from 'react';
import {FlexCentered, Tooltip} from "@ova-studio/react-hyper-admin";
import {MediaTypeIcon} from "../../types/MediaType";
import {Media} from "../../types/Media";
import {useMediaLibraryData, useMediaLibraryDataStore} from "../../MediaLibraryContext";
import {MediaContextMenuTrigger} from "./MediaContextMenu";
import resolveFileIcon from "../../utils/resolveFileIcon";
import {getProcessingStatusIcon, getProcessingStatusInfo} from "../../utils/convertStatusUtils";

type MediaCardProps = {
    data: Media,
}

const MediaCard = ({ data }: MediaCardProps) => {

    const store = useMediaLibraryDataStore();
    const { selectedMedia } = useMediaLibraryData();

    const isSelected = React.useMemo<boolean>(() => selectedMedia.some(i => i.id === data.id), [ selectedMedia ]);
    const processingIcon = getProcessingStatusIcon(data.processing_status);

    return (
        <MediaContextMenuTrigger
            as='div'
            media={data}
            triggerOnLeftClick={!store.allowSelect}
        >
            <div onClick={() => store.handleMediaClick(data)} role='button' className={`media-card ${isSelected ? 'media-card-selected' : ''}`}>
                {data.thumb?.url ? (
                    <img
                        src={data.thumb.url}
                        alt={data.filename}
                        style={{ objectFit: 'cover', backgroundImage: data.thumb?.pixel ? `url(${data.thumb?.pixel})` : '' }}
                        className='media-card-preview'
                    />
                ) : (
                    <FlexCentered addClassName='bg-secondary'>
                        <img src={resolveFileIcon(data.mime_type)} alt={data.filename} height='50%' />
                    </FlexCentered>
                )}
                <div className='media-card-info'>
                    {processingIcon && (
                        <Tooltip text={getProcessingStatusInfo(data.processing_status)}>
                            <i className={`${processingIcon} me-1`}/>
                        </Tooltip>
                    )}
                    {!data.flags.canUseSchema && (
                        <Tooltip text='Для роботи розширених результатів пошуку потрібно додати деяку інформацію до медіа. Скористайтесь меню редагування даних.'>
                            <i className='mdi mdi-file-alert-outline text-danger me-1'/>
                        </Tooltip>
                    )}
                    <i className={`mdi mdi-${MediaTypeIcon[data.type]} me-1`} />
                    {data.filename}
                </div>
            </div>
        </MediaContextMenuTrigger>
    )
}

export default MediaCard
