import {AuthResponse} from "@ova-studio/react-hyper-admin/dist/AppHelpers";
import {AuthUserData} from "@ova-studio/react-hyper-admin";
import API from "@ova-studio/api-helper";

export default class Auth {

    static async login(data: Record<string, any>) : Promise<AuthResponse> {
        const response = await API.post('auth', data);
        return response.data;
    }

    static async getAuth() : Promise<AuthUserData> {
        return await API.getData<AuthUserData>('auth')
    }

    static async logout() : Promise<void> {
        await API.delete('auth', {});
    }

}
