import * as React from "react";
import {ModuleConfig} from "../../helpers/ModulesRegistry";
import {makeCrudRoute} from "@ova-studio/react-hyper-admin";

const FeedbackListWrapper = React.lazy(() => import('./pages/FeedbackListWrapper'));
const FeedbackDisplay = React.lazy(() => import('./pages/FeedbackDisplay'));

const feedbackMessagesDatatableRenderConfig = () => import('./datatable/feedbackMessagesDatatableRenderConfig');

const FeedbackModule : ModuleConfig = {
    navigation: {
        order: 550,
        item: {
            url: '/feedback',
            icon: 'comment-quote',
            name: 'Зворотній зв\'язок',
            counters: {
                'feedback-wait-cnt': 'warning',
            },
        }
    },

    routes: makeCrudRoute({
        type: 'simple',
        basePath: 'feedback',
        baseEndpoint: 'feedback',
        name: 'Зворотній зв\'язок',
        index: {
            name: 'Повідомлення',
            renderConfig: feedbackMessagesDatatableRenderConfig,
            wrapper: FeedbackListWrapper,
        },
        show: {
            name: 'Перегляд повідомлення #{data.id}',
            element: <FeedbackDisplay />,
        },
    })
}

export default FeedbackModule;
