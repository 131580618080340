import * as React from "react";
import clsx from "clsx";
import {mdiAlertBox, mdiAlertCircle, mdiCheckCircle, mdiInformation} from "@mdi/js";
import Icon from "@mdi/react";

type Variant = 'success' | 'error' | 'warning' | 'info';

export type EditorAlertProps = {
    variant: Variant;
    icon?: string | false;
    className?: string;
    noSpace?: boolean;
    children: React.ReactNode,
}

const defaultIconMap : Record<Variant, string> = {
    'success': mdiCheckCircle,
    'error': mdiAlertCircle,
    'warning': mdiAlertBox,
    'info': mdiInformation,
}

const EditorAlertWrapper = ({ children } : { children: React.ReactNode }) => (
    <div className='editor-alert-container'>
        {children}
    </div>
)

const EditorAlert = ({ variant, icon, noSpace, children, className }: EditorAlertProps) => {
    const _icon = React.useMemo(() => {
        if (icon === false) {
            return null;
        }
        return icon || defaultIconMap[variant];
    }, [icon]);

    const Wrapper = noSpace ? EditorAlertWrapper : React.Fragment;

    return (
        <Wrapper>
            <div className={clsx('editor-alert', `editor-alert-${variant}`, className)}>
                {_icon && <Icon path={_icon} className='editor-alert-icon'/>}
                <div className="editor-alert-body">{children}</div>
            </div>
        </Wrapper>
    )
}

export default EditorAlert;
