import {Media} from "../types/Media";
import {SimpleCallback} from "@ova-studio/react-hyper-admin";
import MediaLibraryService from "./MediaLibraryService";

export type MediaId = Media['id'];
export type MediaIds = MediaId[];
export type MediaItemData = Media | undefined | null;

type MediaIdOrIds = MediaId | MediaIds | undefined | null;

type MediaDataStoreData = {
    [key: MediaId]: Media | undefined,
}

type MediaDataStoreDataCache = Record<string, Record<MediaId, MediaItemData>>;

type MediaDataListener = {
    ids: MediaIds,
    callback: () => void,
}

export class MediaDataStore {
    private readonly _service: MediaLibraryService;

    private _ids: MediaIds = [];
    private _data: MediaDataStoreData = {};
    private _dataCache: MediaDataStoreDataCache = {};
    private _listeners: MediaDataListener[] = [];

    private _onDestroy: SimpleCallback[] = [];

    constructor(service: MediaLibraryService) {
        this._service = service;
        this._subscribeToEvents();
    }

    public subscribe(ids: MediaIdOrIds, callback: SimpleCallback) : SimpleCallback {
        if (!ids) {
            return () => {};
        }

        const listener = { ids: Array.isArray(ids) ? ids : [ids], callback };

        this._listeners.push(listener);
        this._handleListenersChange();

        return () => {
            this._listeners = this._listeners.filter(l => l !== listener);
            this._handleListenersChange();
        }
    }

    public getSingleData(id: MediaId | null) : MediaItemData {
        if (!id) {
            return null;
        }

        return this._data[id];
    }

    private _makeData(ids: MediaIds | null) : Record<MediaId, MediaItemData> {
        if (!ids) {
            return {};
        }

        return ids.reduce((acc: Record<MediaId, MediaItemData>, id: MediaId) : Record<MediaId, MediaItemData> => ({
            ...acc,
            [id]: this._data[id] ?? undefined,
        }), {} as Record<MediaId, MediaItemData>);
    }

    private _getCacheKey(ids: MediaIds | null) : string {
        return ids?.sort().join(',') ?? 'null';
    }

    public getData(ids: MediaIds | null) : Record<MediaId, MediaItemData> {
        const key = this._getCacheKey(ids);

        if (!this._dataCache[key]) {
            this._dataCache = {
                ...this._dataCache,
                [key]: this._makeData(ids),
            }
        }

        return this._dataCache[key];
    }

    private _callListeners(ids: MediaIds) {
        this._listeners.forEach(listener => {
            if (listener.ids.some(id => ids.includes(id))) {
                listener.callback();
            }
        });
    }

    private _updateData(media: Media) {
        this._data = {
            ...this._data,
            [media.id]: media,
        }

        this._dataCache = {};

        this._callListeners([media.id]);
    }

    private _updateDataMultiple(media: Media[]) {
        const newData = media.reduce((acc: MediaDataStoreData, m: Media) : MediaDataStoreData => ({
            ...acc,
            [m.id]: m,
        }), {});

        this._data = { ...this._data, ...newData };

        this._dataCache = {};

        this._callListeners(media.map(m => m.id));
    }

    private _deleteData(id: MediaId) {
        const data = this._data;
        delete data[id];
        this._data = { ...data };

        this._dataCache = {};

        this._callListeners([id]);
    }

    private _handleListenersChange() {
        const newIds = this._listeners.reduce((acc: MediaIds, listener: MediaDataListener) : MediaIds => ([...acc, ...listener.ids]), []);

        const removedIds = this._ids.filter(id => !newIds.includes(id));
        const addedIds = newIds.filter(id => !this._ids.includes(id));

        removedIds.length > 0 && this._service.mediaManager.stopWatch('store', removedIds);
        addedIds.length > 0 && this._service.mediaManager.startWatch('store', newIds);

        this._ids = newIds;

        this._loadData();

        this._dataCache = {};
    }

    private _loadData() {
        if (this._ids.length === 0) {
            return;
        }

        this._service.loadMediaList(this._ids)
            .then(this._updateDataMultiple.bind(this));

    }

    private _handleMediaAddOrChange(media: Media) {
        if (!this._ids.includes(media.id)) {
            return;
        }

        this._updateData(media);
    }

    private _handleMediaDelete(media: Media) {
        if (!this._ids.includes(media.id)) {
            return;
        }

        this._deleteData(media.id);
    }

    private _subscribeToEvents() {
        this._onDestroy = [
            ...this._onDestroy,
            this._service.mediaManager.on('created', this._handleMediaAddOrChange.bind(this)),
            this._service.mediaManager.on('updated', this._handleMediaAddOrChange.bind(this)),
            this._service.mediaManager.on('deleted', this._handleMediaDelete.bind(this)),
        ];
    }
}
