import {AbstractDataStore, SimpleCallback} from "@ova-studio/react-hyper-admin";
import {Media} from "../types/Media";
import API, {getApiErrorMessage} from "@ova-studio/api-helper";
import {MediaSelectAction} from "../types/MediaSelectAction";
import {MediaSelectHandler} from "../types/MediaSelectHandler";
import MediaLibraryService from "../services/MediaLibraryService";
import {MediaType} from "../types/MediaType";
import {FolderID} from "../types/MediaFolder";
import FolderManager from "../services/FolderManager";

export type MediaLibraryOptions = {
    types?: MediaType[],
    selectAction?: MediaSelectAction,
    handleSelect?: MediaSelectHandler,
    handleSubmit?: MediaSelectHandler,
}

export type MediaLibraryData = {
    loading: boolean,
    isAllLoaded: boolean,

    page: number,
    search: string,

    media: Media[]|null,
    selectedMedia: Media[],
    error?: string,
}

export default class MediaLibraryDataStore extends AbstractDataStore<MediaLibraryData> {

    private readonly _service: MediaLibraryService;
    private readonly _opts: MediaLibraryOptions;

    private _onDestroy: SimpleCallback[] = [];

    private _reloadTimeout: ReturnType<typeof setTimeout>|null = null;

    private _data : MediaLibraryData = {
        loading: false,
        isAllLoaded: false,

        page: 1,
        search: '',

        media: null,
        selectedMedia: [],
    }

    constructor(service: MediaLibraryService, options: MediaLibraryOptions) {
        super();
        this._service = service;
        this._opts = options;
    }

    private _handleMediaListChange(newMedia: Media[]) {
        const currentIds = this._data.media ? this._data.media.map(m => m.id) : [];
        const newIds = newMedia.map(m => m.id);

        const removedIds = currentIds.filter(id => !newIds.includes(id));
        const addedIds = newIds.filter(id => !currentIds.includes(id));

        removedIds.length > 0 && this._service.mediaManager.stopWatch('media-list', removedIds);
        addedIds.length > 0 && this._service.mediaManager.startWatch('media-list', addedIds);
    }

    private _updateData(data : Partial<MediaLibraryData>) {
        if (data.media) {
            this._handleMediaListChange(data.media);
        }

        this._data = {
            ...this._data,
            ...data,
        }
        this._callListeners();
    }

    private get _context() : string|null {
        if (this._service.folderManager.selectedFolder === FolderManager.CONTEXT_FOLDER) {
            return this._service.currentContext;
        }

        return null;
    }

    private get _selectedFolder() : FolderID|null {
        return this._service.folderManager.realSelectedFolder;
    }

    private async _loadMedia(force?: boolean) {
        if (!force) {
            if (this._data.loading) return;
            if (this._data.isAllLoaded) return;
        }

        this._updateData({
            loading: true,
        })

        try {
            const media = await API.getData<Media[]>(this._service.getEndpoint('media'), {
                page: this._data.page,
                search: this._data.search ?? undefined,
                media_folder_id: this._selectedFolder ?? undefined,
                context: this._context ?? undefined,
                types: this._service.getAllowedTypes(this._opts.types)
            });

            this._updateData({
                media: this._data.media ? [ ...this._data.media, ...media ] : media,
                loading: false,
                page: this._data.page + 1,
                isAllLoaded: media.length < 5,
                error: undefined,
            })
        } catch (e) {
            console.error(e);
            this._updateData({
                loading: false,
                media: this._data.media ? this._data.media : [],
                error: getApiErrorMessage(e)
            })
        }
    }

    public loadMore() {
        void this._loadMedia();
    }

    getData() : MediaLibraryData {
        return this._data;
    }

    public get allowSelect() : boolean {
        return !!this._opts.selectAction && this._opts.selectAction !== MediaSelectAction.None;
    }

    public handleMediaClick(media : Media) {
        switch (this._opts.selectAction) {
            case MediaSelectAction.SelectSingle:
                this._updateData({
                    selectedMedia: [ media ],
                })
                break;

            case MediaSelectAction.SelectMultiple:
                this._updateData({
                    selectedMedia: this._data.selectedMedia.some(m => m.id === media.id)
                        ? this._data.selectedMedia.filter(m => m.id !== media.id)
                        : [ ...this._data.selectedMedia, media ],
                })
                break;
        }

        this._opts.handleSelect?.(this._data.selectedMedia);
    }

    public handleSubmit() {
        this._opts.handleSubmit?.(this._data.selectedMedia);
    }

    private _handleFolderChange() {
        this._updateData({
            isAllLoaded: false,
            page: 1,
            search: '',
            media: null,
        })

        void this._loadMedia();
    }

    private _filterMediaList(media: Media[]) : Media[] {
        return media.filter(m => this._service.getAllowedTypes(this._opts.types).includes(m.type))
            .filter(m => this._selectedFolder ? m.folder_id == this._selectedFolder : true);
    }

    public set search(value : string) {
        this._updateData({
            loading: true,
            isAllLoaded: false,
            page: 1,
            search: value,
            media: null,
        });

        if (this._reloadTimeout) {
            clearTimeout(this._reloadTimeout);
        }

        this._reloadTimeout = setTimeout(() => {
            void this._loadMedia(true);
        }, 500);
    }

    private _handleMediaAdd(media: Media) : void {
        this._updateData({
            media: this._filterMediaList(this._data.media ? [ media, ...this._data.media ] : [ media ]),
        })
    }

    private _handleMediaUpdate(media: Media) {
        if (!this._data.media) return;

        this._updateData({
            media: this._filterMediaList(this._data.media.map(m => m.id === media.id ? media : m)),
        })
    }

    private _handleMediaDelete(media: Media) {
        if (!this._data.media) return;
        this._updateData({
            media: this._data.media.filter(m => m.id !== media.id),
        })
    }

    public init() {
        this._onDestroy.push(
            this._service.folderManager.onFolderChange(this._handleFolderChange.bind(this)),
            this._service.mediaManager.on('created', this._handleMediaAdd.bind(this)),
            this._service.mediaManager.on('updated', this._handleMediaUpdate.bind(this)),
            this._service.mediaManager.on('deleted', this._handleMediaDelete.bind(this)),
        );

        void this._loadMedia();
    }

    public destroy() {
        this._handleMediaListChange([]);
        this._onDestroy.forEach(cb => cb());
        this._onDestroy = [];
    }
}
