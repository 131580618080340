import * as React from "react";
import Icon from "@mdi/react";
import {useBoolean} from "@ova-studio/react-hyper-admin";
import clsx from "clsx";

type EditorDropdownAction = {
    name: string,
    action: () => void,
}

export type EditorDropdownButtonProps = {
    icon: string,
    actions: EditorDropdownAction[],
}

const EditorDropdownButton = ({ icon, actions }: EditorDropdownButtonProps) => {
    const { state: open, toggle, setFalse: close } = useBoolean();

    const handleAction = React.useCallback((handler: (() => void)) => {
        close();
        handler();
    }, []);

    return (
        <div className='ova-editor-block-action-dropdown'>
            <button onClick={() => toggle()}><Icon path={icon}/></button>
            <div className={clsx('ova-editor-block-action-dropdown-content', open && 'open')}>
                {actions.map((action, index) => (
                    <button key={index} onClick={() => handleAction(action.action)}>{action.name}</button>
                ))}
            </div>
        </div>
    )
}

export default EditorDropdownButton;
