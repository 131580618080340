import {App, ModuleConfig} from "./types";
import {GlobalButtonsData, NavigationMenu} from "@ova-studio/react-hyper-admin";
import {RouteObject} from "react-router-dom";
import {ActivityModuleConfig} from "../../modules/ActivityModule";
import {AdsModuleConfig} from "../../modules/AdsModule";
import {AnalyticsModuleConfig} from "../../modules/AnalyticsModule";
import {CommentsModuleConfig} from "../../modules/CommentsModule";
import {FeedbackModuleConfig} from "../../modules/FeedbackModule";
import {MediaModuleConfig} from "../../modules/MediaModule";
import {NoticesModuleConfig} from "../../modules/NoticesModule";
import {PagesModuleConfig} from "../../modules/PagesModule";
import {PoolModuleConfig} from "../../modules/PoolModule";
import {PostsModuleConfig} from "../../modules/PostsModule";
import {SettingsModuleConfig} from "../../modules/SettingsModule";
import {TermsModuleConfig} from "../../modules/TermsModule";
import {NavMenuCounterList} from "@ova-studio/react-hyper-admin/dist/AppHelpers/Navigation/types/MainNavigation";
import API from "@ova-studio/api-helper";

export type ModuleName = string

export default class ModulesRegistry {

    private static _enableModules: Record<ModuleName, boolean> = {
        'activity': true,
        'ads': true,
        'analytics': true,
        'comments': true,
        'feedback': true,
        'media': true,
        'notices': true,
        'pages': true,
        'pool': true,
        'posts': true,
        'terms': true,
        'settings': true,
    }

    private static _modules: Record<string, ModuleConfig> = {
        'activity': ActivityModuleConfig,
        'ads': AdsModuleConfig,
        'analytics': AnalyticsModuleConfig,
        'comments': CommentsModuleConfig,
        'feedback': FeedbackModuleConfig,
        'media': MediaModuleConfig,
        'notices': NoticesModuleConfig,
        'pages': PagesModuleConfig,
        'pool': PoolModuleConfig,
        'posts': PostsModuleConfig,
        'terms': TermsModuleConfig,
        'settings': SettingsModuleConfig,
    };

    private static _isModuleEnabled(name: ModuleName) {
        return ModulesRegistry._enableModules[name] ?? false;
    }

    public static enableModule(name: ModuleName) {
        this._enableModules[name] = true;
    }

    public static disableModule(name: ModuleName) {
        this._enableModules[name] = false;
    }

    public static registerModule(name: ModuleName, config: ModuleConfig) {
        this._modules[name] = config;
        this.enableModule(name);
    }

    public static async getNavigation() : Promise<NavigationMenu> {

        return Object.entries(this._modules)
            .filter(([key]) => this._isModuleEnabled(key))
            .map(([_, i]) => Array.isArray(i.navigation) ? i.navigation : (i.navigation ? [i.navigation] : []))
            .flat()
            .sort((item1, item2) => item1.order - item2.order)
            .map(i => i.item);
    }

    public static async getGlobalButtons() : Promise<GlobalButtonsData> {
        return {
            create: {
                variant: 'primary',
                icon: 'plus',
                title: 'Створити',
                order: 99,
                items: {},
            }
        }
    }

    public static async getNavCounters(app: App) : Promise<NavMenuCounterList> {
        if (!app.auth.isAuth()) {
            return {};
        }

        return API.getData<NavMenuCounterList>('nav/counters');
    }

    public static getRoutes() : RouteObject[] {
        return Object.entries(this._modules)
            .filter(([key]) => this._isModuleEnabled(key))
            .map(([_,i]) => i.routes)
            .filter(i => !!i)
            .flat(1) as RouteObject[];
    }

    public static initModules(app: App) {
        for (const [ key, module ] of Object.entries(this._modules)) {
            if (!this._isModuleEnabled(key)) {
                continue;
            }

            module.init?.(app);
        }
    }
}
