import * as React from "react";
import {AppConfig, Application} from "@ova-studio/react-hyper-admin";
import {ModulesRegistry} from "./helpers/ModulesRegistry";
import Logo from "./assets/Logo";
import Auth from "./core/Auth";
import {App} from "./helpers/ModulesRegistry/types";
import './init';
import searchTypes from "./core/searchTypes";
import {MediaLibrary} from "@ova-studio/react-media-library";
import {EditorConfig} from "@ova-studio/react-block-editor";

const adminDomain = window.location.hostname;
const siteDomain = adminDomain.replace('admin.', '');

const appConfig : AppConfig = {
    base: {
        appName: 'OVA Engine',
        appCreateYear: 2021,
        logoComponent: Logo,
        siteDomain: siteDomain,
    },

    actions: {
        endpoint: 'actions',
    },

    search: {
        endpoint: 'search',
        types: searchTypes,
    },

    auth: {
        loginHandler: Auth.login,
        authUserResolver: Auth.getAuth,
        logoutHandler: Auth.logout,
    },

    navigation: {
        navigationMenuResolver: () => ModulesRegistry.getNavigation(),
        globalButtonsResolver: () => ModulesRegistry.getGlobalButtons(),
        navigationCountersResolver: (app) => ModulesRegistry.getNavCounters(app),
        topbarButtonsResolver: async () => [
            {
                icon: 'application-import',
                title: 'Перейти на сайт',
                onClick: () => window.open(`https://${siteDomain}`, '_blank'),
                variant: 'link',
            }
        ],
    },

    external: {
        rtc: {
            rtcWsUrl: `wss://${adminDomain}/rtc/ws/`,
            baseEndpoint: 'collaboration',
        },
        media: {
            endpoint: 'media',
        },
        websocket: {
            host: adminDomain,
            port: '443',
            appKey: 'ws-admin',
            authEndpoint: '/api/broadcasting/auth',
        }
    }
}

const onAppReady = (app: App) => {
    ModulesRegistry.initModules(app);
    MediaLibrary.initializer(app);
}

export type OvaEngineAppConfig = {
    sentryDsn?: string;
    appRelease?: string;
}

export type OvaEngineAdminProps = {
    config?: OvaEngineAppConfig,
    editorConfig?: Partial<EditorConfig>,
}

const OvaEngineAdmin = ({ config: instanceConfig, editorConfig } : OvaEngineAdminProps) => {
    const config : AppConfig = React.useMemo(() => ({
        ...appConfig,
        ...(instanceConfig ? {
            base: {
                ...appConfig.base,
                ...instanceConfig,
            },
        } : {}),
        ...(editorConfig ? {
            external: {
                ...appConfig.external,
                editor: editorConfig,
            },
        } : {}),
    }), []);

    const appRoutes = React.useMemo(() => ModulesRegistry.getRoutes(), []);

    return (
        <Application
            appRoutes={appRoutes}
            config={config}
            onAppReady={onAppReady}
        />
    )
}

export default OvaEngineAdmin
