import API from "@ova-studio/api-helper";
import {AppLayout} from "@ova-studio/react-hyper-admin";

AppLayout.singleton({
    layoutColor: ['dark'],
    leftbarTheme: ['dark'],
});

API.setConfig({
    baseUrl: '/api/',
    timeout: 10000,
});

