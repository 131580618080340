import * as React from "react";
import {Media} from "../types/Media";
import JustifiedGallery from "@ova-studio/justified-gallery";
import PictureRender from "./PictureRender";

export type MediaGalleryProps = {
    items: Media[],
    captions?: Record<number, string>,
    lazy?: boolean,
}

type MediaGalleryItem = {
    media: Media,
    caption?: string,
}

const MediaGallery = ({ items, captions, lazy }: MediaGalleryProps) => {

    const galleryRef = React.useRef<HTMLDivElement>(null);
    const fjGalleryRef = React.useRef<JustifiedGallery>();

    const galleryItems: MediaGalleryItem[] = React.useMemo(() => {
        return items.map(item => ({
                media: item,
                caption: captions?.[item.id],
            }));
    }, [ items, captions ]);

    React.useEffect(() => {
        if (!galleryRef.current) return;

        if (fjGalleryRef.current instanceof JustifiedGallery) {
            fjGalleryRef.current.updateImages();
            fjGalleryRef.current.justify(true);
            return;
        }

        fjGalleryRef.current = new JustifiedGallery(galleryRef.current, {
            itemSelector: '.ova-gallery__item',
            gutter: 2,
            lastRow: 'center',
            rowHeight: 200,
            rowHeightTolerance: 0.2,
        });
    }, [galleryItems]);

    return (
        <div className='ova-gallery' ref={galleryRef}>
            {galleryItems.map((item) => (
                <span key={item.media.id} className='ova-gallery__item' data-url={item.media.dataUrl}
                      data-caption={item.caption}>
                    {item.media.preview && (
                        <PictureRender data={item.media.preview!} alt={item.caption} lazy={lazy}/>
                    )}
                </span>
            ))}
        </div>
    )
}

export default MediaGallery;
