import * as React from "react";
import {ModuleConfig} from "../../helpers/ModulesRegistry";
import {makeSimpleRoute} from "@ova-studio/react-hyper-admin";

const ActivityInfo = React.lazy(() => import('./pages/ActivityInfo'));

const ActivityModule : ModuleConfig = {

    routes: [
        makeSimpleRoute({
            path: '/activity',
            name: 'Деталі активності',
            element: <ActivityInfo />,
            loader: {
                sync: {
                    data : {
                        endpoint: 'activity',
                        queryString: true,
                    },
                },
            },
        }),
    ],

}

export default ActivityModule;
