import {ProcessingStatus} from "../types/ProcessingStatus";

export const getProcessingStatusIcon = (status: ProcessingStatus) : string|null => {
    switch (status) {
        case ProcessingStatus.Pending:
        case ProcessingStatus.Processing:
            return 'mdi mdi-sync text-warning';
        case ProcessingStatus.Failed:
            return 'mdi mdi-alert-circle-outline text-danger';
        case ProcessingStatus.Done:
            return 'mdi mdi-check-circle-outline text-success';
        default:
            return null;
    }
}

export const getProcessingStatusInfo = (status: ProcessingStatus) : string => {
    switch (status) {
        case ProcessingStatus.Pending:
            return 'Очікує на обробку';
        case ProcessingStatus.Processing:
            return 'Обробка';
        case ProcessingStatus.Failed:
            return 'Помилка обробки';
        case ProcessingStatus.Done:
            return 'Обробка завершена';
        default:
            return '';
    }
}
