import * as React from "react";
import {MediaDisplayProps} from "./MediaDisplayProps";
import PictureRender from "../PictureRender";

const MediaImageDisplay = ({ data, caption, lazy }: MediaDisplayProps) => {

    if (!data.image) {
        return null;
    }

    return (
        <figure className='ova-engine-image'>
            <PictureRender data={data.image} alt={caption} lazy={lazy} isProcessing={!data.is_processed} />
            {caption && <figcaption>{caption}</figcaption>}
        </figure>
    )
}

export default MediaImageDisplay;
