import * as React from "react";
import {AppConfig, DateTimeFormat} from "@ova-studio/react-hyper-admin";
import {DateTime} from "luxon";

const searchTypes : AppConfig['search']['types'] = {
    posts: {
        icon: 'file-document-check-outline',
        name: 'Опубліковані',
        allowCheck: (app) => app.auth.can('posts.public::view'),
        render: ({ data }) => (
            <React.Fragment>
                <b>{data.title}</b>
                <span className='ms-auto text-muted small'>
                    <DateTimeFormat dt={data.publish_dt} children={dt => dt.toLocaleString(DateTime.DATETIME_SHORT)} />
                </span>
                {data.author && (
                    <span className='ms-1 text-muted small'>
                        <i className='mdi mdi-account' />{' '}
                        {data.author?.name}
                    </span>
                )}
            </React.Fragment>
        ),
    },
    drafts: {
        icon: 'file-document-edit',
        name: 'Чернетки',
        allowCheck: (app) => app.auth.canAny([ 'posts.drafts::all', 'posts.drafts::create' ]),
        render: ({ data }) => (
            <React.Fragment>
                <b>{data.title}</b>
                <span className='ms-auto text-muted small'>
                    <DateTimeFormat dt={data.publish_dt} children={dt => dt.toLocaleString(DateTime.DATETIME_SHORT)}/>
                </span>
                {data.author && (
                    <span className='ms-1 text-muted small'>
                        <i className='mdi mdi-account'/>{' '}
                        {data.author?.name}
                    </span>
                )}
            </React.Fragment>
        ),
    },
    pools: {
        icon: 'poll',
        name: 'Опитування',
        allowCheck: (app) => app.auth.can('pools::all'),
        render: ({ data }) => (
            <React.Fragment>
                <b>{data.txt}</b>
                <span className='ms-auto text-muted small'>
                    <DateTimeFormat dt={data.created_at} children={dt => dt.toLocaleString(DateTime.DATETIME_SHORT)}/>
                </span>
            </React.Fragment>
        ),
    },
    notices: {
        icon: 'bulletin-board',
        name: 'Оголошення',
        allowCheck: (app) => app.auth.can('notices::manage'),
        render: ({ data }) => (
            <React.Fragment>
                <b>{data.title}</b>
                <span className='ms-auto text-muted small'>
                    <DateTimeFormat dt={data.publish_dt} children={dt => dt.toLocaleString(DateTime.DATETIME_SHORT)}/>
                </span>
            </React.Fragment>
        ),
    },
    categories: {
        icon: 'book-open-variant',
        name: 'Категорії',
        allowCheck: (app) => app.auth.can('terms.categories::manage'),
    },
    tags: {
        icon: 'tag',
        name: 'Теги',
        allowCheck: (app) => app.auth.can('terms.tags::manage'),
    },
    pages: {
        icon: 'file-document',
        name: 'Сторінки',
        allowCheck: (app) => app.auth.can('pages.static::manage'),
    },
    users: {
        icon: 'account',
        name: 'Користувачі адмінпанелі',
        allowCheck: (app) => app.auth.can('settings.users::manage'),
    },
    // ['site-users']: {
    //     icon: 'account-card-outline',
    //     name: 'Користувачі порталу',
    //     allowCheck: (app) => app.auth.can('settings.site-users::manage'),
    // },
}

export default searchTypes;
