import * as React from "react";
import {ModuleConfig} from "../../helpers/ModulesRegistry";
import {makeCrudRoute, makeSimpleRoute} from "@ova-studio/react-hyper-admin";
import userFormDefaults from "./form/userFormDefaults";

const GeneralSettingsForm = React.lazy(() => import('./pages/GeneralSettingsForm'));
const UserForm = React.lazy(() => import('./pages/UserForm'));
const SiteUserEditForm = React.lazy(() => import('./pages/SiteUserEditForm'));

const NavigationList = React.lazy(() => import('./pages/NavigationList'));
const NavigationEditForm = React.lazy(() => import('./pages/NavigationEditForm'));

const siteUsersDatatableRenderConfig = () => import('./datatable/siteUsersDatatableRenderConfig');

const SettingsModuleConfig : ModuleConfig = {
    navigation: {
        order: 1000,
        item: {
            icon: 'cog',
            name: 'Налаштування',
            children: [
                {
                    url: '/settings/general',
                    name: 'Загальні',
                },
                {
                    url: '/settings/navigation',
                    name: 'Навігація',
                },
                {
                    url: '/settings/users',
                    name: 'Користувачі адмінпанелі',
                },
                {
                    url: '/settings/site-users',
                    name: 'Користувачі сайту',
                },
            ],
        },
    },

    routes: [
        makeSimpleRoute({
            path: '/settings/general',
            loader: {
                sync: {
                    'data': {
                        endpoint: 'settings/general',
                    }
                },
                defer: {
                    'posts/categories': { endpoint: 'helpers/terms/categories' },
                    'posts/ukr-net-categories': { endpoint: 'helpers/posts/other/ukr-net-categories' },
                    'pages-list': { endpoint: 'helpers/settings/general/pages-list' },
                    'fixed-button-positions': { endpoint: 'helpers/settings/general/fixed-button-positions' },
                    'comments/enable-status': { endpoint: 'helpers/comments/enable-status' },
                    'org/types': { endpoint: 'helpers/settings/general/org/types' },
                    'ads/badge-positions': { endpoint: 'helpers/settings/general/ads-badge-position' },
                }
            },
            name: 'Загальні',
            element: <GeneralSettingsForm />
        }),
        makeSimpleRoute({
            path: '/settings/navigation',
            name: 'Навігація',
            children: [
                makeSimpleRoute({
                    index: true,
                    name: 'Навігаційні меню',
                    loader: {
                        sync: {
                            'data': {
                                endpoint: 'settings/navigation',
                            }
                        },
                    },
                    element: <NavigationList />,
                }),
                makeSimpleRoute({
                    path: 'edit/:id',
                    name: 'Редагувати меню {data.name}',
                    element: <NavigationEditForm />,
                    loader: {
                        sync: {
                            'data': { endpoint: 'settings/navigation/{id}' },
                        },
                        defer: {
                            'list-special-pages': { endpoint: 'helpers/settings/navigation/list/special-page' },
                            'list-pages': { endpoint: 'helpers/settings/navigation/list/page' },
                            'list-categories': { endpoint: 'helpers/settings/navigation/list/category' },
                            'list-tags': { endpoint: 'helpers/settings/navigation/list/tag' },
                        }
                    }
                }),
            ],
        }),
        makeCrudRoute({
            type: 'simple',
            basePath: 'settings/users',
            baseEndpoint: 'settings/users',
            name: 'Редакція',
            index: {
                name: 'Редакція',
            },
            forms: {
                createName: 'Додати редактора',
                updateName: 'Редагувати редактора {data.name}',
                element: <UserForm />,
                initialData: userFormDefaults,
                helpers: {
                    'permissions': 'helpers/users/permissions',
                    'roles': 'helpers/users/roles',
                }
            },
        }),
        makeCrudRoute({
            type: 'simple',
            basePath: 'settings/site-users',
            baseEndpoint: 'settings/site-users',
            name: 'Користувачі',
            index: {
                name: 'Користувачі',
                renderConfig: siteUsersDatatableRenderConfig,
            },
            update: {
                name: 'Редагувати користувача {data.name}',
                element: <SiteUserEditForm />,
            }
        }),
    ],
}

export default SettingsModuleConfig;
